<template>
  <section>
    <app-card-liquidacion
      v-if="item"
      :title="item.nombre_etapa"
      :fecha_inicio="getFechaFormated(item?.fecha_hora_inicio)"
      :fecha_fin="getFechaFormated(item?.fecha_hora_fin)"
      :dias="getDays(item?.fecha_hora_inicio, item?.fecha_hora_fin)"
      :observaciones="item?.observacion"
    >
    </app-card-liquidacion>
    <!-- <DataTable :headers="headers" :items="items"> </DataTable> -->
  </section>
</template>

<script>
import { mapState } from "vuex";

import DataTable from "@/components/DataTableComponent.vue";

export default {
  name: "SubastaInversaComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 9,
    },
  },
  components: {
    DataTable,
  },
  data: () => ({
    headers: [
      {
        text: "Subasta",
        value: "subasta",
        sortable: true,
      },
      {
        text: "Sala",
        value: "sala",
        sortable: true,
      },
      {
        text: "Proveedor",
        value: "proveedor",
        sortable: false,
        align: "center",
      },
      {
        text: "Oferta($)",
        value: "oferta",
        sortable: false,
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        sortable: false,
        align: "center",
      },
    ],
    items: [
      {
        subasta: "Subasta 1",
        sala: "Sala 1",
        proveedor: "Proveedor 1",
        oferta: "S/ 1,000.00",
        estado: "Ganador",
      },
      {
        subasta: "Subasta 2",
        sala: "Sala 2",
        proveedor: "Proveedor 2",
        oferta: "S/ 1,000.00",
        estado: "Ganador",
      },
      {
        subasta: "Subasta 3",
        sala: "Sala 3",
        proveedor: "Proveedor 3",
        oferta: "S/ 1,000.00",
        estado: "Ganador",
      },
    ],
    item: null,
  }),
  methods: {
    async getEtapasResumen() {
      const { data } = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa
      );
      this.item = data.etapaProceso;
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
