<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <p class="text-h5 secondary--text mb-0">Resumen del proceso</p>
        <p class="text-subtitle-2 secondary--text">
          {{ items.nombre_proceso }}
        </p>
        <p class="text-h5 secondary--text mb-0">Forma de contratación</p>
        <p class="text-subtitle-2 secondary--text">
          {{ items.forma_contratacion }}
        </p>
        <p class="text-h5 secondary--text mb-0">Código</p>
        <p class="text-subtitle-2 secondary--text">
          {{ items.codigo_proceso }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="!$vuetify.breakpoint.xs">
        <div>
          <v-row class="pt-5">
            <v-tabs vertical v-model="tab" background-color="bgMinsal">
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in items.etapaProceso"
                :key="index"
                class="secondary--text d-flex justify-start"
              >
                <!-- <v-icon>{{ item.icono }}</v-icon> -->

                <span class="pl-1">{{ item.nombre_etapa }} </span>
              </v-tab>
              <v-tabs-items v-model="tab" style="width: 100%">
                <v-tab-item v-for="(item, index) in contenido" :key="index">
                  <component class="mt-3 pr-3" :is="item.content" />
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-row>
        </div>
      </v-col>
      <!-- <v-col v-else cols="12">
        <app-tabs :contenido="contenido" :items="items" />
      </v-col> -->
    </v-row>
  </section>
</template>

<script>
import DescargaBases from "./components/Liquidacion/DescargaBasesComponent.vue";
import AdendasEnmiendas from "./components/Liquidacion/AdendasEnmiendasComponent.vue";
import RecepcionOfertas from "./components/Liquidacion/RecepcionOfertasComponent.vue";
import AperturaOfertas from "./components/Liquidacion/AperturaOfertaComponent.vue";
import EvaluacionOfertas from "./components/Liquidacion/EvaluacionOfertaComponent.vue";
import Resultados from "./components/Liquidacion/ResultadosComponent.vue";
import RecursoRevision from "./components/Liquidacion/RecursoRevisionComponent.vue";
import Consulta from "./components/Liquidacion/ConsultaViewComponent.vue";
import Contratacion from "./components/Liquidacion/ContratacionComponent.vue";
import Cierre from "./components/Liquidacion/CierreComponent.vue";
import Detalle from "./components/Liquidacion/DetalleGralContratoComponent.vue";
import SubastaInversa from "./components/Liquidacion/SubastaInversaComponent.vue";
import ResultadosFinales from "./components/Liquidacion/ResultadosFinalesComponent.vue";
import { mapMutations } from "vuex";
export default {
  name: "resumenLiquidacionView",
  components: {
    DescargaBases,
    Consulta,
    AdendasEnmiendas,
    RecepcionOfertas,
    AperturaOfertas,
    EvaluacionOfertas,
    Resultados,
    RecursoRevision,
    Contratacion,
    Cierre,
    Detalle,
    ResultadosFinales,
  },
  data: () => ({
    tab: null,
    contenido: [],
    items: [],
    algo: [],
  }),
  methods: {
    ...mapMutations("procesoCompraUACI", [
      "setProcesoCompraUACI",
      "setIdProcesoCompraUACI",
      "setItems"
    ]),

    async getResumenEtapas() {
      const { data } = await this.services.Paac.getResumenEtapas(
        this.$route.params.id_proceso
      );
      this.items = data;
      this.setItems(data);
      this.setProcesoCompraUACI(this.items.etapaProceso);
      this.setIdProcesoCompraUACI(this.$route.params.id_proceso);
      this.items.etapaProceso.map((item) => {
        let res = this.getEtapa(item.id_etapa);
      });
    },

    getEtapa(id) {
      if (id === 1) {
        this.contenido.push({ content: DescargaBases });
      } else if (id === 2) {
        this.contenido.push({ content: Consulta });
      } else if (id === 3) {
        this.contenido.push({ content: AdendasEnmiendas });
      } else if (id === 4) {
        this.contenido.push({ content: RecepcionOfertas });
      } else if (id === 5) {
        this.contenido.push({ content: AperturaOfertas });
      } else if (id === 7 || id === 12) {
        this.contenido.push({ content: EvaluacionOfertas });
      } else if (id === 9) {
        this.contenido.push({ content: SubastaInversa });
      } else if (id === 10) {
        this.contenido.push({ content: Resultados });
      } else if (id === 11) {
        this.contenido.push({ content: RecursoRevision });
      } else if (id === 13) {
        this.contenido.push({ content: ResultadosFinales });
      } else if (id === 14) {
        this.contenido.push({ content: Contratacion });
      }
    },
  },
  computed: {},
  created() {
    this.getResumenEtapas();
  },
};
</script>
