<template>
  <section>
    <app-card-liquidacion
      v-if="item"
      :title="item.data.etapaProceso.nombre_etapa"
      :fecha_inicio="
        getFechaFormated(item?.data.etapaProceso.fecha_hora_inicio)
      "
      :fecha_fin="getFechaFormated(item?.data.etapaProceso.fecha_hora_fin)"
      :dias="
        getDays(
          item?.data.etapaProceso.fecha_hora_inicio,
          item?.data.etapaProceso.fecha_hora_fin
        )
      "
      :observaciones="item?.data.etapaProceso.observacion"
    >
      <template #content>
        <p class="mb-0">Ofertas presentadas</p>
        <DataTable
          :headers="headers"
          :items="item.data.ganadores.resultados"
          v-models:select="per_page"
          v-models:pagina="page"
          :total_registros="Number(item.headers.total_rows)"
          @paginar="paginate"
          v-if="item.data.id_forma_contratacion === 3"
        >
        </DataTable>
        <DataTable
          :headers="headers2"
          :items="item.data.ganadores"
          :show_actions="true"
          :actions="actions"
          v-else
        >
        </DataTable>
      </template>
    </app-card-liquidacion>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";

export default {
  name: "ResultadosFinalesComponent",
  props: {
    idEtapa: {
      type: Number,
      default: 13,
    },
  },
  components: {
    DataTable,
  },
  data: () => ({
    headers: [
      {
        text: "Subasta",
        value: "subasta",
        sortable: true,
      },
      {
        text: "Sala",
        value: "sala",
        sortable: true,
      },
      {
        text: "Proveedor",
        value: "proveedor",
        sortable: false,
        align: "center",
      },
      {
        text: "Oferta($)",
        value: "oferta",
        sortable: false,
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        sortable: false,
        align: "center",
      },
    ],
    headers2: [
      {
        text: "Ofertante",
        value: "ofertante",
        sortable: false,
      },
      {
        text: "Monto total ",
        value: "monto_total",
      },
      {
        text: "Opciones",
        value: "actions",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver Resultado",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye-outline",
        },
        eventName: "showResult",
      },
    ],
    pagination: true,
    page: 1,
    per_page: 2,
    item: null,
  }),
  methods: {
    async getEtapasResumen() {
      const params = {
        pagination: this.pagination,
        page: this.page,
        per_page: this.per_page,
      };
      const data = await this.services.Paac.getResumenEtapasDetalle(
        this.idProcesoCompra,
        this.idEtapa,
        params
      );
      this.item = data;
      this.page = Number(data.headers.page);
      this.per_page = Number(data.headers.per_page);
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.page = Number(pagina);
      this.per_page = Number(cantidad_por_pagina);
      this.getEtapasResumen();
    },
  },
  computed: {
    ...mapState({
      idProcesoCompra: (state) => state.procesoCompraUACI.id_proceso_compra,
    }),
  },
  created() {
    this.getEtapasResumen();
  },
};
</script>

<style></style>
